input, textarea, keygen, select, button {
font-size: 1.2em;
line-height: 1.5em;
padding:0.2em;
margin-right: 2em;
font-family: Schoolbell, Swanky and Moo Moo, Sacramento, Happy Monkey, Homemade Apple, gaegu, Zeyada, Gloria Hallelujah, Dawning of a New Day, Dr Sugiyama, Crafty Girls, Cedarville Cursive, Annie Use Your Telescope, Calligraffitti, Beth Ellen, Architects Daughter,  Allan, Caveat, The Girl Next Door, La Belle Aurore, Indie Flower, Nothing You Could Do, Reenie Beanie;
}

textarea {
  width: 40em;
  height: 8em;
}

input[type="radio"], input[type="checkbox"]{
 margin-right: 1em;
}

fieldset {
  font-size: 1.2em;
  background-color: white;
  background-color: var(--color-bg1);
  border: none;
  margin-bottom: 2em;
  margin-left: 0;
}

.column {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  max-width: 100%;
  padding: 0.5em 1em 0.5em 1em;
}

.scale label, .column label, .matrix label {
  margin: 0.2em;
  line-height: 1em;
  display: inline;
  padding: 0.2em 0.6em 0.2em 0.6em;
  border: thin solid white;
  border: thin solid var(--color-bg1);
}

.scale label {
  margin: 0.2em;
  line-height: 1em;
  display: inline;
  padding: 0.4em 0.6em 0.4em 0.6em;
  border: thin solid white;
  border: thin solid var(--color-bg1);
}

.scale label:hover, .column label:hover, .matrix label:hover {
  color: black;
  color: var(--color-text-accent);
  border: thin solid aqua;
  border: thin solid var(--color-accent);
  background-color: #ccfff2;
  background-color: var(--color-accent2);
}

input[type="text"], input[type="email"] {
  padding: 0.5em 0.2em 0.5em 0.2em;
  height: 1.5em;
}

meter {
  display:block; width:50%; height:1em;
}

span {
  line-height: 1.5em;
  padding:0.2em;
}

:checked+span {
  background-color: #ccfff2;
  background-color: var(--color-accent2);
  border-bottom: dotted medium teal;
  border-bottom: dotted medium var(--color-accent2);
}

.scale, .matrix {
  width: 98%;
  display: grid;
  grid-gap: 0.2em 0.2em;
  margin-bottom: 0em;
}

.scale {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 2fr;
}

.matrix {
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.scale label, .matrix label {
  text-align: center;
  border-color: linen;
  border-color: var(--color-bg2);
}

.matrix div {
  border: solid thin white;
  border: solid thin var(--color-bg1);
  text-align: center;
}

.first {
  padding: 0em;
}

.even {
  background-color: white;
  background-color: var(--color-bg1);
  padding: 0.2em;
}

.odd {
  background-color: linen;
  background-color: var(--color-bg2);
  padding: 0.2em;
}

.odd label {
  border-color: white;
  border-color: var(--color-bg1);
}

.statement {
  grid-column: 1;
  border: none;
}

@media screen and (min-width: 640px) {
  .scale input, .matrix input {
    display: block;
    box-sizing: border-box;
    margin: 0.5em auto 0.5em auto;
  }

  .hide, .mstatement, .matrix span {
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    width: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
  }

  .specify {
  line-height: 1.2em;
  font-size: 1.2em;
  margin-top: -0.2em;
  margin-bottom: 2em;
  max-width: 32.5em;
  }
  .matrix input:checked, .matrix input:enabled, .matrix input:focus, .matrix input {
    margin: 0em auto 0em auto;
    height: 100%;
    line-height: 100%;
    //find out why it's jumping on selected in safari...
  }
}

@media screen and (max-width: 640px) {
  input[type="text"], textarea, select, button {
    margin: auto;
    margin-bottom: 2em;
    display: block;
    width: 98%;
  }

  .scale, .matrix, .column {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    max-width: 98%;
  }

  .column label, .scale label, .matrix label {
    width: 88%;
    text-align: left;
    padding: 0.5em 1em 0.5em 1em;
    height: 1.5em;
  }

  .matrix .statement {
    margin-top: 1em;
  }

  .th, .matrix legend {
    display: none;
  }

  .specify {
  line-height: 1.2em;
  font-size: 1.2em;
  margin-top: -0.2em;
  margin-bottom: 2em;
  width: 100%;
  }

}
