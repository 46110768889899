
* {
    box-sizing: border-box;
    line-height: 1.5;
}

header, footer {
  // background-color: var(--color-accent);
  padding: 1em;
  padding-left: 2.5em;
  margin: -0.5em;
  margin-bottom:0;
}

.Ergonomie-des-jeux {
  background: url(/assets/images/UXJV.svg) no-repeat top right, var(--color-accent);
}

.Creation-de-jeux {
  background: url(/assets/images/JV.svg) no-repeat center right, var(--color-accent);
}

.Ergonomie {
  background: url(/assets/images/UX.svg) no-repeat top right, var(--color-accent);
}

.Enjmin {
  background: url(/assets/images/JV.svg) no-repeat bottom right, var(--color-bg2);
}

.Archive {
  background: url(/assets/images/JV.svg) no-repeat bottom right, var(--color-bg2);
}

p, ul {
  margin-bottom: 2em;
}

nav {
  max-width: 100%;
  padding-left: 1em;
  display: flex;
  flex-direction: column;
  padding:0;
}

nav a, .mobileOnly, div a {
  margin: .5em;
  padding: .5em 1em .5em 1em;
}

nav a img {
  float: left;
  max-width: 1.5em;
  margin-right: 0.3em;
}

#markdown-toc {
  padding-right: 1em;
  line-height: 1em;
  border: thin solid var(--color-accent);
  background-color: var(--color-bg2);
}

#markdown-toc li {
  padding-bottom: 0.6em;
  line-height: 1em;
}

#markdown-toc::before {
  Content: "Index";
  font-size: 1.5em;
  line-height: 2em;
}

main {
  padding: 1em;
}

hr {
border-top: thin dotted var(--color-accent);
border-bottom: thin dotted var(--color2);
}

section {
    display:flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;
    flex: 2 100%;
    row-gap: 2em;
    column-gap: 2em;
}

section a {
    flex: 1 50%;
    border-top: 3px dotted var(--color-accent);
    padding: 0.4em;
    margin: 0;
    margin-bottom: 0em;
    padding-left: 0em;
}

section div  {
  display:flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1 100%;
  row-gap: .5em;
  column-gap: .5em;
}

section div a {
  flex: 1 100%;
  border-top: 3px dotted var(--color-bg1);
}

section div .title {
  flex: 100%;
  border-top: 3px dotted var(--color-accent);
}


img {
  width: 97%;
}

table {
  width: 97%;
  background-color:var(--color-bg2);
}

tr, th, td  {
  padding: 1em;
  background-color: var(--color-bg1);
}

th {
  background-color:var(--color-bg2);
  border: var(--color-bg1) 2px solid;
}

@media screen and (min-width:45em) {
  hr, article {
  max-width: var(--width2);
  }

  #markdown-toc {
  max-width: var(--width1);
  float: right;
  margin:1em;
  margin-right: -10em;
  }

  section div a {
    flex: 1 50%;
    border-top: 3px dotted var(--color-bg1);
  }

  nav {
    margin: auto;
    display: flex;
    flex-flow: row wrap;
  }

  nav a {
    margin: 0 1em 0 1em;
    padding: .5em 1em .5em 1em;
  }
  .mobileOnly {
    display:none;
    visibility: hidden;
  }
}

@media screen and (min-width:70em) {

  #markdown-toc {
  margin-right: var(--width-1);
  }

  article section {
    width: 150%;
  }

  section a {
    flex: 1 45%;
    padding: .5em 1em .5em 1em;
  }

  section div a {
    flex: 1 30%;
    border-top: 3px dotted var(--color-bg1);
  }
}

@media screen and (min-width:90em) {
  main {
    padding-left: 5em;
  }

  article section {
    width: 200%;
  }
}
