a {
  color: var(--color2);
  text-decoration: none;
}

a:hover {
  background: var(--color-bg2);
  color: var(--color-text);
}

#markdown-toc li a {
  border: 3px solid var(--color-bg2);
}

header div a, footer div a {
  background: var(--color-bg2);
  border: var(--color-accent2) dotted medium;
}

footer div a {
  position: fixed;
  bottom: 10px;
  right: 10px;
}

header div a:hover, footer div a:hover {
  background: var(--color-accent2);
  border: var(--color-bg2) dotted medium;
}

nav a {
    margin-right: 1em;
    border-bottom: 3px dotted white;
}

nav a:hover, #markdown-toc li a:hoverm {
  border-bottom: 3px dotted var(--color-accent2);
  background: var(--color-bg2);
}
 section a:hover {
  border-top: 3px dotted var(--color-accent2);
}
