/* theme colors*/
:root {
  --color-bg1: white; //white
  --color-text: #001D1F; //noir
  --color-bg2: #FAF0E6; //beige
  --color2: #00838F; //teal
  --color-accent: #63FFDA; //turquoise bg
  --color-accent2: #E91D63; //pink
  --color-secret: #fffff8; //yellow
  --width1: 25em;
  --width-1: -25em;
  --width2: 45em;
}

@media (prefers-color-scheme: dark) {
:root{
  --color-bg1: #001D1F; //darkgreen
  --color-text: #FAF0E6; //beige
  --color-bg2: black; //black
  --color2: #00ACC1; //blue
  --color-accent: #00808B; //teal
  --color-accent2: #E91D63; //pink
  --color-secret: #fffff8; //black2
  }
}

body {
  font-family: Montserrat, Trebuchet MS, sans-serif;
  color: var(--color-text);
  background: var(--color-bg1);
  // letter-spacing: 0.12em;
  // word-spacing: 0.16em;
  // background-color: ghostwhite;
}

h1 p {
  padding: 0;
  margin: 0;
}

// styles for lists of articles

a h3 {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 1.3em;
}

a p {
  margin: 0.2em;
  margin-left:0;
}

small{
  font-weight: 300;
}
